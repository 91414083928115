import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/ui-components/icon/icon.component';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { AlertService } from '../service/alert.service';
import { ALERT_ICONS, ALERT_STATE } from '../types/alert.types';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { ButtonSeverity } from '../../button/button.types';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, ButtonComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  animations: [
    trigger('alert', [
      state('open', style({ transform: 'translateY(0%)', opacity: 1 })),
      state('close', style({ transform: 'translateY(-200%)', opacity: 0 })),
      transition('void => open', [animate('300ms ease-in-out')]),
      transition('open => close', [animate('300ms ease-in-out')]),
    ]),
    trigger('expand', [
      state('collapsed', style({ height: '0px', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class AlertComponent {
  private readonly alertService: AlertService = inject(AlertService);

  protected readonly isDisplayed = this.alertService.isDisplayed;
  protected readonly severity = this.alertService.severity;
  protected readonly title = this.alertService.title;
  protected readonly description = this.alertService.description;
  protected readonly isExpanded = this.alertService.expanded;

  protected readonly severityClass = computed(() => ({
    success: this.severity() === ALERT_STATE.success,
    warning: this.severity() === ALERT_STATE.warning,
    danger: this.severity() === ALERT_STATE.danger,
    info: this.severity() === ALERT_STATE.info,
  }));

  protected readonly hasDescription = computed(() => Boolean(this.description()) && this.description().length > 0);

  protected toggleExpand(): void {
    this.alertService.toggleExpand();
  }

  protected closeAlert(): void {
    this.alertService.hide();
  }

  protected get iconAlertType(): IconName {
    return this.getIconForSeverity(this.severity());
  }

  private getIconForSeverity(severity: string): IconName {
    const iconMap: Record<string, IconName> = {
      [ALERT_STATE.success]: ALERT_ICONS.success,
      [ALERT_STATE.warning]: ALERT_ICONS.warning,
      [ALERT_STATE.danger]: ALERT_ICONS.danger,
      [ALERT_STATE.info]: ALERT_ICONS.info,
    };

    return iconMap[severity] || ALERT_ICONS.info;
  }

  protected get alertSeverity(): ButtonSeverity {
    return this.getAlertSeverityClass(this.severity());
  }

  private getAlertSeverityClass(severity: string): ButtonSeverity {
    const severityMap: Record<string, ButtonSeverity> = {
      [ALERT_STATE.success]: ButtonSeverity.success,
      [ALERT_STATE.warning]: ButtonSeverity.warning,
      [ALERT_STATE.danger]: ButtonSeverity.danger,
      [ALERT_STATE.info]: ButtonSeverity.info,
    };

    return severityMap[severity] || 'info';
  }
}
