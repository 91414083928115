import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { InterceptorLabels } from '@models/enums/interceptors-label.enum';
import { D365GW, GATEWAY_TARGET, TARGET } from '../services/finops/D365-gateway.service';

export const D365GatewayInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const isD365GatewayRequest = req.headers.get(TARGET)?.includes(D365GW);
  const isCrudRequest = req.headers.get(GATEWAY_TARGET)?.includes(InterceptorLabels.CRUD_REQUESTS) ?? false;

  if (!isD365GatewayRequest) {
    return next(req); // Pass through if not D365GW request
  }

  if (!isCrudRequest) {
    return next(req); // Pass through if not a CRUD request
  }

  console.log(`D365GatewayInterceptor Request: ${req.method} ${req.urlWithParams}`);

  return next(req).pipe(
    tap(evt => {
      if (evt instanceof HttpResponse) {
        return handleSuccess(evt, req, next); // Handle success or errors
      }
      return of(null);
    }),
    catchError(handleError) // Handle errors
  );
};

function handleError(error: HttpErrorResponse): Observable<never> {
  let errorMessage: string;

  if (error.error instanceof ErrorEvent) {
    errorMessage = `Error: ${error.error.message}`; // Client-side error
  } else {
    errorMessage = `Error ${error.status}: ${error.statusText}`; // Server-side error
  }

  if (error.status === 400) {
    errorMessage = 'Bad Request (400): Please check your request.';
  } else if (error.status === 401) {
    errorMessage = 'Unauthorized (401): You are not authorized to access this resource.';
  }

  alert(errorMessage); // Display error message
  return throwError(() => error);
}

function handleSuccess(evt: HttpResponse<unknown>, req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown> | null> {
  switch (evt.status) {
    case 200:
    case 201:
    case 204:
      console.log(`D365GatewayInterceptor Request successful, code: ${evt.status}`);
      return next(req); // Continue to next handler

    case 400:
      alert('Bad Request');
      document.location.reload(); // Reload the page and stop execution
      return of(null);

    case 401:
      alert('Unauthorized');
      document.location.reload(); // Reload the page and stop execution
      return of(null);

    default:
      return next(req); // Continue to next handler
  }
}
