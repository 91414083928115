<div *ngIf="isDisplayed()" [@alert]="isDisplayed() ? 'open' : 'close'" class="alert-container">
    <div class="alert-wrapper" [ngClass]="severityClass()">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center alert-title pe-3">
            <app-icon size="lg" [name]="iconAlertType" package="regular"></app-icon>
          </div>
          <div class="d-flex align-items-center alert-title">
            {{ title() }}
          </div>
        </div>

        <div class="d-flex align-items-center">
          @if (description()) {
          <app-button
            class="d-flex align-items-center me-1"
            [text]="(isExpanded() ? 'GL.MSG.HideDetails' : 'GL.MSG.ShowDetails') | translate"
            (click)="toggleExpand()"
            variant="full"
            [severity]="alertSeverity"
          ></app-button>
          }

          <app-button
            class="d-flex align-items-center"
            [text]="'GL.ACTIONS.Close' | translate"
            (click)="closeAlert()"
            variant="part"
            [severity]="alertSeverity"
          ></app-button>
        </div>
      </div>
      @if (description()) {
      <div [@expand]="isExpanded() ? 'expanded' : 'collapsed'" [ngClass]="{ 'expanded-description': isExpanded() }">
        {{ description() }}
      </div>
      }
    </div>
  </div>