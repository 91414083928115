import { Component, type OnInit, inject, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslationService } from '@core/services/translation/translation.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { LoggerService } from '@core/services/log/logger.service';
import { environment } from '@environments/environment';
import { NavbarComponent } from '@core/layout/navbar/navbar.component';
import { FooterComponent } from '@core/layout/footer/footer.component';
import { ThemeService } from '@core/services/utilities/theme.service';
import { ErrorComponent } from '@core/layout/error.component';
import { KeycloakService } from 'keycloak-angular';
import { LoginService } from '@core/services/login/login.service';
import { LoaderComponent } from '@shared/ui-components/loader/component/loader.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertComponent } from './shared/ui-components/alert/component/alert.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    FooterComponent,
    NavbarComponent,
    AlertComponent,
    ErrorComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private readonly translationService: TranslationService = inject(TranslationService);
  private readonly logger: LoggerService = inject(LoggerService);
  private readonly loginService: LoginService = inject(LoginService);
  private readonly themeService: ThemeService = inject(ThemeService);
  private readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  
  destroyRef: DestroyRef = inject(DestroyRef);
  keycloakService: KeycloakService = inject(KeycloakService);
  navigationService: NavigationService = inject(NavigationService);
  
  userProfile = this.navigationService.getUser();

  isNavbarCollapsed: boolean = false;
  isDarkTheme: boolean= false;

  async ngOnInit(): Promise<void> {
    this.logger.displayObjectDebug(this.userProfile, 'userProfile');
    this.logger.info(`AppComponent env : ${environment.name}`);
    this.translationService.setLanguageByNavigator();

    await this.loginService.updateLogin();

    this.setupThemeObserver();
    this.setupBreakpointObserver();
  }

  private setupThemeObserver(): void {
    this.themeService.isDarkTheme$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDarkTheme: boolean) => {
      this.isDarkTheme = isDarkTheme;
      this.applyTheme();
    });
  }

  private setupBreakpointObserver(): void {
    this.breakpointObserver
      .observe(['(max-width: 991px)'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        this.isNavbarCollapsed = state.matches;
      });
  }

  /**
   * Applies the dark theme to the document body based on the current state of the 'isDarkTheme' property.
   */
  private applyTheme() {
    document.body.classList.toggle('dark-theme', this.isDarkTheme);
  }

  /**
   * Toggles the collapsed state of the navbar.
   */
  onToggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
