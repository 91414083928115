import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonComponent } from "../../shared/ui-components/button/button.component";

@Component({
  selector: 'app-draft-dialog',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule, ButtonComponent],
  template: `
    <div>
      <div class="mb-4">
        <p>
          {{ 'GL.MSG.AreYouSureToQuitDraft' | translate }}
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <app-button
          [text]="'GL.ACTIONS.Cancel' | translate"
          (click)="onCancel()"
          variant="basic"
          severity="primary"
          class="me-2"
        ></app-button>

        <app-button
          [text]="'GL.LABELS.QuitWithoutSaving' | translate"
          (click)="onConfirm()" 
          variant="full"
          severity="danger"
          [background]="false"
        ></app-button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class DraftDialogComponent implements OnInit {
  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.ref.onClose.subscribe();
  }

  onConfirm() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }
}
