// button.types.ts
export const ButtonVariant = {
    full: 'full',
    part: 'part',
    basic: 'basic',
    text: 'text'
  } as const;
  export type ButtonVariant = keyof typeof ButtonVariant;
  
  export const ButtonSeverity = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger'
  } as const;
  export type ButtonSeverity = keyof typeof ButtonSeverity;
  
  export const ButtonSize = {
    small: 'small',
    medium: '',
    large: 'large'
  } as const;
  export type ButtonSize = keyof typeof ButtonSize;
  
  export const IconPosition = {
    before: 'before',
    after: 'after'
  } as const;
  export type IconPosition = keyof typeof IconPosition;