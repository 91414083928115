import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthGuardData, createAuthGuard } from 'keycloak-angular';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { environment } from '@environments/environment'; // Assurez-vous que le client ID est bien défini ici

const isAccessAllowed = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authData: AuthGuardData
): Promise<boolean> => {
  const { keycloak } = authData;
  const router = inject(Router);
  const navigationService = inject(NavigationService);

  // Verify if user is authenticated and redirect to login URI if not authenticated
  const authenticated = await keycloak.authenticated;
  if (!authenticated) {
    await keycloak.login({
      scope: 'openid profile email roles web-origins',
      idpHint: 'azure-ad',
      redirectUri: window.location.origin + state.url,
    });
    return false;
  }

  const clientId = environment.keycloak.initconfig.clientId; // Here : 'logilab'
  const userClientRoles = keycloak.resourceAccess?.[clientId]?.roles || [];

  navigationService.setUserRoles(userClientRoles);

  // Roles defined in app.routes.ts
  const requiredRoles = route.data['roles'] || [];
  if (requiredRoles.length === 0) {
    return true;
  }

  // Verify if user has required roles
  const hasRequiredRole = requiredRoles.some((role: string) => userClientRoles.includes(role));

  if (!hasRequiredRole) {
    await router.navigate(['/forbidden']);
    return false;
  }

  return true;
};

export const canActivateKeycloakAuthRole = createAuthGuard(isAccessAllowed);
