import { Injectable, inject } from '@angular/core';
import { type ActivatedRouteSnapshot, Router, type RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthGuardExtension extends KeycloakAuthGuard {
  protected readonly navigationService = inject(NavigationService);
  constructor (
    protected override readonly router: Router,
    protected readonly keycloakService: KeycloakService
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let accessAllowed: boolean = false;
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakService.login({
        scope: 'openid profile email roles web-origins',
        idpHint: 'azure-ad',
        redirectUri: window.location.origin + state.url
      });

      this.navigationService.setUserRoles(this.keycloakService.getUserRoles());
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      accessAllowed = true;
    } else {
      // allow if all roles needed are includes
      // accessAllowed = requiredRoles.every((someRequiredRole: string) => this.roles.includes(someRequiredRole));

      //allow if at least one role needed is included
      accessAllowed = requiredRoles.some((someRequiredRole: string) => this.roles.includes(someRequiredRole));
    }

    return accessAllowed;
  }
}
