import { environment } from '@environments/environment';
import {
  provideKeycloak,
  createInterceptorCondition,
  IncludeBearerTokenCondition,
  INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
  withAutoRefreshToken,
  AutoRefreshTokenService,
  UserActivityService,
} from 'keycloak-angular';

/**
 * Source example :
 * https://github.com/mauriciovigolo/keycloak-angular/blob/v19.0.2/projects/examples/standalone/src/app/keycloak.config.ts
 **/
const createUrlPattern = (url: string) => {
  // delete / to the end
  //verifiy if may be we have an https in start
  const baseUrl = url.replace(/\/$/, '');
  // use pattern https:\/\/myUrl.com for create regex
  return new RegExp(`^(${baseUrl})(\/.*)?$`, 'i');
};

const gatewayCondition = createInterceptorCondition<IncludeBearerTokenCondition>({
  urlPattern: createUrlPattern(environment.gateway.url),
});

const foCondition = createInterceptorCondition<IncludeBearerTokenCondition>({
  urlPattern: createUrlPattern('https://' + environment.fo.domain)
});


export const provideKeycloakAngular = () =>
  provideKeycloak({
    config: environment.keycloak.initconfig,
    initOptions: {
      checkLoginIframe: false,
      enableLogging: true,
      flow: 'standard' /* type KeycloakFlow = 'standard'|'implicit'|'hybrid' */,
    },
    features: [
      withAutoRefreshToken({
        onInactivityTimeout: 'logout',
        sessionTimeout: 1800000,
      }),
    ],
    providers: [
      AutoRefreshTokenService,
      UserActivityService,
      {
        provide: INCLUDE_BEARER_TOKEN_INTERCEPTOR_CONFIG,
        useValue: [gatewayCondition, foCondition],
      },
    ],
  });
