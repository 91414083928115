import { inject } from "@angular/core";
import { CanDeactivateFn } from "@angular/router";
import { DraftDialogComponent } from "@app/front-views/shared/draft-dialog.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable } from "rxjs";
import { NavigationService } from "../services/navigation/navigation.service";
import { TranslateService } from "@ngx-translate/core";

export const draftGuard: CanDeactivateFn<unknown> = () => {
  const navigationService = inject(NavigationService);
  const dialogService = inject(DialogService);
  const translateService = inject(TranslateService);
  let dialogRef: DynamicDialogRef | undefined;

  const headerTitle = translateService.instant('GL.LABELS.Warning');  
  const showDialog = (): Observable<boolean> => {
    return new Observable(observer => {
      if (dialogRef) {
        dialogRef.close();
        dialogRef = undefined;
      }

      dialogRef = dialogService.open(DraftDialogComponent, {
        header: headerTitle,
        width: window.innerWidth < 576 ? '100%' : '500px',
        contentStyle: { padding: '1.25rem' },
        styleClass: 'draft-dialog',
        closeOnEscape: false,
        closable: false,
        dismissableMask: false,
        modal: true
      });

      const subscription = dialogRef.onClose.subscribe((result: boolean) => {
        observer.next(result);
        observer.complete();
        
        if (result) {
          navigationService.setDraftMode(false);
        }
        
        subscription.unsubscribe();
      });

      return () => {
        subscription.unsubscribe();
        if (dialogRef) {
          dialogRef.close();
          dialogRef = undefined;
        }
      };
    });
  };

  if (!navigationService.getDraftMode()) {
    return new Observable(observer => {
      observer.next(true);
      observer.complete();
    });
  }

  return showDialog();
};