
import { type KeycloakService } from 'keycloak-angular';
import { environment } from '@environments/environment';

export function initFactory (keycloak: KeycloakService) {
  return async () =>
    await keycloak.init({
      config: environment.keycloak.initconfig,
      initOptions: {
        checkLoginIframe: false,
        enableLogging: true,
        flow: 'standard' /* type KeycloakFlow = 'standard'|'implicit'|'hybrid' */
      },
      bearerExcludedUrls: ['/oauth2/token']
    });
}
