<div *ngIf="isDisplayed()" [@alert]="isDisplayed() ? 'open' : 'close'" class="alert-container">
  <div class="alert-wrapper" [ngClass]="severityClass()">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="alert-title pe-3">
          <app-icon class="fa-lg" [icon]="iconAlertType" package="regular"></app-icon>
        </div>
        <div class="alert-title">
          {{ title() }}
        </div>
      </div>

      <div class="d-flex align-items-center">
        @if (description()) {
        <button class="d-flex align-items-center me-2 expand-button" (click)="toggleExpand()">
          @if (isExpanded()) {
          <span>{{ 'GL.MSG.HideDetails' | translate }}</span>
          } @else {
          <span>{{ 'GL.MSG.ShowDetails' | translate }}</span>
          }
        </button>
        }
        <button class="d-flex align-items-center close-button" (click)="closeAlert()">
          <span>{{ 'GL.ACTIONS.Close' | translate }}</span>
        </button>
        <!-- <button class="d-flex align-items-center bttn-icon-info" (click)="closeAlert()">
              <app-icon class="fa-lg" icon="xmark" package="solid"></app-icon>
            </button> -->
      </div>
    </div>
    @if (description()) {
    <div [@expand]="isExpanded() ? 'expanded' : 'collapsed'" [ngClass]="{ 'expanded-description': isExpanded() }">
      {{ description() }}
    </div>
    }
  </div>
</div>
