/* eslint-disable @typescript-eslint/promise-function-async */
import { type Routes } from '@angular/router';
import { draftGuard } from './core/guards/draft.guard';
import { canActivateKeycloakAuthRole } from './core/auth/guards/keycloak-auth-role.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./front-views/home/home.component').then((c) => c.HomeComponent),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
  },
  {
    path: 'order',
    loadComponent: () => import('./front-views/order/order.component').then((c) => c.OrderComponent),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
  },
  {
    path: 'order/detail/:dataAreaId/:salesId',
    loadComponent: () =>
      import('./front-views/order/components/order-details/order-details.component').then(
        (c) => c.OrderDetailsComponent,
      ),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
    canDeactivate: [draftGuard],
  },
  {
    path: 'order/report/errors',
    loadComponent: () =>
      import('./front-views/order/components/order-errors/order-errors.component').then((c) => c.OrderErrorsComponent),
    data: {
      roles: ['logilab-logistician', 'logilab-admin'],
    },
    canActivate: [canActivateKeycloakAuthRole],
  },
  {
    path: 'catalog',
    loadComponent: () => import('./front-views/catalog/catalog.component').then((c) => c.CatalogComponent),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
  },
  {
    path: 'catalog/detail/:dataAreaId/:refName',
    loadComponent: () =>
      import('./front-views/catalog/components/catalog-details/catalog-details.component').then(
        (c) => c.CatalogDetailsComponent,
      ),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
  },
  {
    path: 'sample',
    loadComponent: () => import('./front-views/test/test.component').then((c) => c.TestComponent),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
    canDeactivate: [draftGuard],
  },
  {
    path: 'forbidden',
    loadComponent: () => import('./front-views/forbidden/forbidden.component').then((c) => c.ForbiddenComponent),
    data: { roles: ['logilab-user'] },
  },
  {
    path: '**',
    loadComponent: () => import('./front-views/not-found/not-found.component').then((c) => c.NotFoundComponent),
    data: { roles: ['logilab-user'] },
    canActivate: [canActivateKeycloakAuthRole],
  },
];
