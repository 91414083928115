import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/ui-components/icon/icon.component';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { AlertService } from '../service/alert.service';
import { ALERT_ICONS, TOAST_STATE } from '../types/alert.type';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  animations: [
    trigger('alert', [
      state('open', style({ transform: 'translateY(0%)', opacity: 1 })),
      state('void', style({ transform: 'translateY(-200%)', opacity: 0 })),
      transition('void => open', [animate('300ms ease-in-out')]),
      transition('open => void', [animate('300ms ease-in-out')]),
    ]),
    trigger('expand', [
      state('collapsed', style({ height: '0px', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class AlertComponent {
  private readonly alertService: AlertService = inject(AlertService);

  isDisplayed = computed(() => this.alertService['displayed']());
  severity = computed(() => this.alertService['severity']());
  title = computed(() => this.alertService['title']());
  description = computed(() => this.alertService['description']());
  isExpanded = computed(() => this.alertService['expanded']());

  severityClass = computed(() => ({
    'success': this.severity() === TOAST_STATE.success,
    'warning': this.severity() === TOAST_STATE.warning,
    'danger': this.severity() === TOAST_STATE.danger,
    'info': this.severity() === TOAST_STATE.info
  }));

  hasDescription = computed(() => 
    Boolean(this.description()) && this.description().length > 0
  );

  // Méthodes pour interagir avec le service
  toggleExpand(): void {
    this.alertService.toggle();
  }

  closeAlert(): void {
    this.alertService.close();
  }

  get iconAlertType(): IconName {
    switch (this.severity()) {
      case TOAST_STATE.success:
        return ALERT_ICONS.success;
      case TOAST_STATE.warning:
        return ALERT_ICONS.warning;
      case TOAST_STATE.danger:
        return ALERT_ICONS.danger;
      case TOAST_STATE.info:
      default:
        return ALERT_ICONS.info;
    }
  }
}
