<app-alert></app-alert>
<div class="app-container d-flex vh-100">
  @defer() {
  <app-navbar
    class="navbar-container"
    (toggle)="onToggleNavbar()"
    [class.navbar-collapsed]="isNavbarCollapsed"
    [isNavbarCollapsed]="isNavbarCollapsed"
    [userProfile]="userProfile"
  ></app-navbar>
  <div class="main-content" [class.navbar-collapsed]="isNavbarCollapsed">
    <app-loader>
      <main class="overflow-auto">
        <router-outlet></router-outlet>
      </main>
    </app-loader>
    <app-footer class="footer"></app-footer>
  </div>
  } @error {
  <app-error errorText="An error occurred"></app-error>
  }
</div>
